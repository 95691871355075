import { graphql } from 'gatsby';
import React from 'react';
import MarketingLayout from '../components/layout/MarketingLayout';
import UntitledStyle from '../components/layout/UntitledStyle';
import LibraryCategoryOrCollectionPage from '../components/library/LibraryCategoryOrCollectionPage';
import LibraryWithCategoriesLayout from '../components/library/LibraryWithCategoriesLayout';
import { LibraryCategoriesYaml } from '../graphql-types';
import { toLibraryCategory } from '../utils/toLibraryType';

interface LibraryCollectionTemplateProps {
  pageContext: {
    slug: string;
    collectionSlug: string | undefined;
  };
  data: {
    category: LibraryCategoriesYaml;
  };
}

const LibraryCollectionTemplate: React.SFC<LibraryCollectionTemplateProps> = ({
  pageContext: { collectionSlug },
  data: { category },
}) => {
  const categoryForPage = toLibraryCategory(category);
  const collectionForPage = collectionSlug
    ? categoryForPage.collections.find(
        (collection) => collection.slug === collectionSlug,
      )
    : undefined;

  return (
    <MarketingLayout
      metaTitle={
        collectionForPage
          ? `${collectionForPage.name} - Slab Library`
          : undefined
      }
    >
      <UntitledStyle>
        <LibraryWithCategoriesLayout>
          <LibraryCategoryOrCollectionPage
            category={categoryForPage}
            collection={collectionForPage}
          />
        </LibraryWithCategoriesLayout>
      </UntitledStyle>
    </MarketingLayout>
  );
};

export const query = graphql`
  query LibraryCollectionPageQuery($slug: String!) {
    category: libraryCategoriesYaml(fields: { slug: { eq: $slug } }) {
      ...LibraryCategoryFragment
      collections {
        name
        description
        slug
        posts {
          ...LibraryPostFragment
        }
      }
    }
  }
`;

export default LibraryCollectionTemplate;
